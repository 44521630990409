export const GOAL_COLOR_MAP = {
  'Share and connect': 'var(--share-and-connect)',
  'Reflect and align': 'var(--reflect-and-align)',
  'Engage and collaborate': 'var(--engage-and-collaborate)',
  'Care and support': 'var(--care-and-support)',
};

export const GOAL_COLOR_MAP_EXPORT = {
  'Share and connect': '#fcecf4',
  'Reflect and align': '#eef5f7',
  'Engage and collaborate': '#fef0e6',
  'Care and support': '#a1af00',
};

export const IS_INTERNAL_KEY = 'DecapCMSIsInternal';
