'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';

import styles from './NavigationLink.module.scss';

type NavigationLinkProps = {
  children: React.ReactNode;
  href: string;
};

export default function NavigationLink({ children, href }: NavigationLinkProps) {
  const pathname = usePathname();

  const current = pathname.includes(href);

  return (
    <a className={classNames(styles.text, { [styles.current]: current })} href={href}>
      {children}
    </a>
  );
}
